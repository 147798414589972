import React from "react";

import { appURLs } from "@/app/app-urls.mjs";
import * as SOON from "@/game-cs2-marketing/constants/comingSoon.mjs";
import PROFILE_LINKS from "@/game-cs2-marketing/constants/profile-links.mjs";
import { GAME_SYMBOL_CS2 } from "@/game-csgo/definition-symbol.mjs";
import ComingSoon from "@/shared/ComingSoon.jsx";

const TITLE = ["common:navigation.cs2MapsStats", "CS2 Maps Stats"];
const DESCRIPTION = [
  "",
  "Uncover your comprehensive and historical map data in CS2 with our stats tracker. Featuring real-time charts that break down Win rate, Terrorist win rate, Counter-Terrorist win rate, and Total Matches after each game you play! With our Game Mode, Season, and Stat filter, you'll be able to quickly tailor your stats to suite your needs.",
];

function ProfileMaps() {
  return (
    <ComingSoon
      gameSymbol={GAME_SYMBOL_CS2}
      pageTitle={TITLE}
      pageLinks={PROFILE_LINKS}
      splashImage={`${appURLs.CDN}/blitz/cs2/comingSoon/cs2-soon-profile-weapons.webp`}
      splashTitle={SOON.TITLE}
      splashDecription={SOON.DESCRIPTION}
      footerTitle={SOON.FOOTER_TITLE}
      footerDescription={SOON.FOOTER_DESC}
    />
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default ProfileMaps;
